export const defaultTrip = {
    pickup: '',
    pickupIsland: '',
    return: '',
    returnIsland: '',
    from: '',
    until: '',
    promo: '',
    cdp: '',
    dob: '',
    res: '',
    status: '',
    paid: false,
    recorded: false,
    conf: '',
    car: '',
    initCar: '',
    rid: '',
    cover: '',
    notes: '',
    afterHoursPickup: false,
    extras: [],
    rates: {},
    options: [],
    estimate: {},
    payment: {},
}

export default {
    app: {
        messages: [],
        worker: {
            getRates: 'ready',
            getOptions: 'ready',
            getEstimate: 'ready',
            newReservation: 'ready',
            getPromoRates: 'ready',
            getCMSVehicles: 'ready',
            getCMSLocations: 'ready',
            getCMSOptions: 'ready',
            getCMSCovers: 'ready',
            getCMSPartners: 'ready',
            getCMSRegions: 'ready',
            getCMSAges: 'ready',
            getCMSLocales: 'ready',
            checkPromocode: 'ready',
            checkRelocation: 'ready',
            resendEmailConfirmation: 'ready',
        },
    },
    quotation: {
        trip: 1,
        split: false,
        splitDateSelected: 0,
        splitCreateEntered: false,
        phase: 'searchresult',
        ferry: false,
        optin: false,
        mode: 'default',
        relocationID: 0,
        relocationCars: {},
        country: '',
        isAmendment: false,
        depositAllowed: true,
        trips: {
            1: defaultTrip,
            2: defaultTrip,
        },
        customer: {
            custno: '',
            firstname: '',
            lastname: '',
            email: '',
            gender: '',
            street1: '',
            street2: '',
            city: '',
            postalcode: '',
            statecode: '',
            countrycode: '',
            phone: '',
            birthdate: '',
            birthplace: '',
            birthcountry: '',
            licno: '',
            licissuer: '',
            licexp: '',
            flightno: '',
            arrival: '',
        },
        ferryData: {
            adult: {
                price: '72.00',
                details: [],
            },
            child: {
                price: '35.00',
                details: [],
            },
            infant: {
                price: '0.00',
                details: [],
            },
            departure: {
                date: 'Select Date',
                time: '',
                company: '',
            },
        },
    },
    models: {
        locations: {},
        vehicles: {},
        options: {},
        covers: {},
        locales: {},
        partners: {},
        regions: [],
        ages: {},
        promocode: {},
        relocation: {},
    },
}
