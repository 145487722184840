import { all } from 'redux-saga/effects'
import { watchRatesRequest } from './ratesSaga'
import { watchOptionsRequest } from './optionsSaga'
import { watchEstimateRequest } from './estimateSaga'
import { watchReservationRequest } from './reservationSaga'
import { watchModelsRequest } from './modelsSaga'
import { watchAnalyticsRequest } from './analyticsSaga'

export default function* rootSaga() {
    yield all([
        watchRatesRequest(),
        watchOptionsRequest(),
        watchEstimateRequest(),
        watchModelsRequest(),
        watchReservationRequest(),
        watchAnalyticsRequest(),
    ])
}
