import { takeLatest, put, call } from 'redux-saga/effects'
import callApi from './helpers'

const getConfig = params => {
    return {
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(params),
    }
}

export function* getOptions(params) {
    try {
        yield call(callApi, () => fetch('/api', getConfig(params)), 'getOptions')
    } catch (error) {
        yield put({ type: 'getOptions.ex', error: error })
    }
}

export function* watchOptionsRequest() {
    yield takeLatest('getOptions', getOptions)
    // yield takeEvery('getOptions', getOptions);
}
