import { takeLatest, takeEvery, put, call } from 'redux-saga/effects'
import callApi from './helpers'

const getConfig = params => {
    return {
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(params),
    }
}

export function* newReservation(params) {
    try {
        yield call(callApi, () => fetch('/api', getConfig(params)), 'newReservation')
    } catch (error) {
        yield put({ type: 'newReservation.ex', error: error })
    }
}

export function* retrieveReservation(params) {
    try {
        yield call(callApi, () => fetch('/api', getConfig(params)), 'retrieveReservation')
    } catch (error) {
        yield put({ type: 'retrieveReservation.ex', error: error })
    }
}

export function* updateReservation(params) {
    try {
        yield call(callApi, () => fetch('/api', getConfig(params)), 'updateReservation')
    } catch (error) {
        yield put({ type: 'updateReservation.ex', error: error })
    }
}

export function* resendEmailConfirmation(params) {
    try {
        yield call(
            callApi,
            () => fetch('/cms/bookingconfirmation', getConfig(params)),
            'resendEmailConfirmation',
        )
    } catch (error) {
        yield put({ type: 'resendEmailConfirmation.ex', error: error })
    }
}

export function* watchReservationRequest() {
    // yield takeLatest('newReservation', newReservation)
    yield takeEvery('newReservation', newReservation)
    yield takeLatest('retrieveReservation', retrieveReservation)
    yield takeLatest('updateReservation', updateReservation)
    yield takeLatest('resendEmailConfirmation', resendEmailConfirmation)
}
