import React, { createContext } from 'react'
import _ from 'lodash'

export const demystify = contextStr => {
    return JSON.parse(
        atob(
            atob(contextStr)
                .split('')
                .reverse()
                .join(''),
        )
            .split('')
            .reverse()
            .join(''),
    )
}

const Context = React.createContext({})

export default Context
