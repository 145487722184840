import _ from 'lodash'
import numeral from 'numeral'
import moment from 'moment'

export const sanctionRates = (quotation, models, context) => {
    const currentTrip = quotation.trips[quotation.trip]

    const rates = _.chain(currentTrip.rates)
        .forEach((rate, key) => {
            rate = _.chain(currentTrip.rates[quotation.mode])
                //.filter(r => r.Availability == "Available")
                .sortBy([r => parseFloat(r.Estimate)])
                .value()
        })
        .value()

    let currentRates = _.cloneDeep(
        currentTrip.promo != '' && _.has(rates, 'promo') ? rates.promo : rates[quotation.mode],
    )

    //expose discount and return current rate.
    if (_.has(rates, 'default')) {
        rates.default.map((dr, i) => {
            const pr = _.find(currentRates, { Class: dr.Class })
            if (dr.Estimate != pr.Estimate) {
                _.set(currentRates, `[${i}].Discount`, dr.Estimate - pr.Estimate)
            }
        })
    }

    return { rates, currentRates }
}

export const sactionVehicles = (quotation, models, context) => {
    if (!_.isEmpty(models.vehicles)) {
        const cars = _.chain(models.vehicles)
            .filter({ uber_vehicle: quotation.mode == 'uber' ? 1 : 0 })
            .transform((result, value) => {
                result[value.class] = value
            }, {})

        return cars.value()
    }
}

export const fullcoverblackoutdates = (quotation, models, context, sactionedCars, carClass) => {
    let globalblackoutdates = {}
    let blackoutdates = {}

    if (!_.isNil(sactionedCars[carClass])) {
        // globalblackoutdates = sactionedCars[carClass].global_blackout_dates;
        blackoutdates = sactionedCars[carClass].blackout_dates
    }

    if (!_.isEmpty(globalblackoutdates)) {
        _.forEach(globalblackoutdates, gbdr => {
            if (moment(quotation.trips[quotation.trip].from).isBetween(gbdr.from, gbdr.to)) {
                return false
            }
        })
    }

    if (!_.isEmpty(blackoutdates)) {
        _.forEach(blackoutdates, bdr => {
            if (moment(quotation.trips[quotation.trip].from).isBetween(bdr.from, bdr.to)) {
                return false
            }
        })
    }

    return true
}

export const fullcoverwhitelistlocation = (quotation, models, context, sactionedCars, carClass) => {
    if (
        !_.isNil(sactionedCars[carClass]) &&
        !_.isEmpty(sactionedCars[carClass].bundled_cover_locations)
    ) {
        return sactionedCars[carClass].bundled_cover_locations
    }
    return []
}

export const generate = (
    quotation,
    models,
    context,
    sactionedCars,
    rentaldays,
    pickupLoc,
    fullcoverblackoutdates,
    fullcoverwhitelistlocation,
    filters,
    sort,
    currentRates,
) => {
    const currentTrip = quotation.trips[quotation.trip]

    let blackoutV = []
    if (!_.isEmpty(quotation.trips[quotation.trip].promo) && _.has(models.promocode, 'CDP')) {
        if (
            !_.isEmpty(models.promocode.blackoutvehicles) &&
            models.promocode.blackoutvehicles != 0
        ) {
            blackoutV = _.map(models.promocode.blackoutvehicles, vehID => {
                return _.find(models.vehicles, { id: parseInt(vehID) }) !== undefined
                    ? _.find(models.vehicles, { id: parseInt(vehID) }).class
                    : ''
            })
        }
    }

    if (_.has(models.promocode, 'restrictedVehicleLocations')) {
        let restrictedVLoc = models.promocode.restrictedVehicleLocations
        restrictedVLoc.map((v, i) => {
            if (!_.isEmpty(v.pickupOnly) || !_.isEmpty(v.dropoffOnly)) {
                if (
                    _.indexOf(v.pickupOnly, quotation.trips[quotation.trip].pickup) == -1 ||
                    _.indexOf(v.dropoffOnly, quotation.trips[quotation.trip].return) == -1
                ) {
                    if (_.indexOf(blackoutV, v.Class) == -1) {
                        blackoutV.push(v.Class)
                    }
                }
            }
        })
    }

    let excludeFilter = ['FV', 'XA']
    let mobileExcludeFilter = ['FV', 'XA']
    if (
        quotation.trips[quotation.trip].promo.toUpperCase() == 'FATHERSDAY' ||
        quotation.trips[quotation.trip].promo.toUpperCase() == 'BLACKFRIDAY' ||
        quotation.trips[quotation.trip].promo.toUpperCase() == 'BOXINGDAY' ||
        quotation.trips[quotation.trip].promo.toUpperCase() == 'NEWYEAR'
    ) {
        excludeFilter.push('ZA')
        mobileExcludeFilter.push('ZA')
    }

    if (!_.isEmpty(blackoutV)) {
        excludeFilter = _.union(excludeFilter, blackoutV)
        mobileExcludeFilter = _.union(mobileExcludeFilter, blackoutV)
    }

    // BEGIN New - Tile Parent/Child Checks
    // New Lodash
    // let includeChildFilter = {};
    // _.each( sactionedCars, ( car, key ) => {
    //     //Check Parent/Child Bundles
    //     if(!_.isNil(car.parent_class)
    //         && !_.includes(excludeFilter,car.parent_class)
    //         && !_.includes(excludeFilter,key)
    //         && !(quotation.mode== 'default'
    //             && currentTrip.promo.trim() == ''
    //             && (fullcoverblackoutdates(key)
    //                 && fullcoverwhitelistlocation(key).indexOf(pickupLoc.class) != -1
    //                 && car.bundled_cover == '1'
    //                 && rentaldays <= parseInt(car.bundled_max_days))
    //             || (fullcoverblackoutdates(car.parent_class)
    //                 && fullcoverwhitelistlocation(car.parent_class).indexOf(pickupLoc.class) != -1
    //                 && sactionedCars[car.parent_class].bundled_cover == '1'
    //                 && rentaldays <= parseInt(sactionedCars[car.parent_class].bundled_max_days))
    //             && _.some(currentRates, {Class: car.parent_class})
    //     )) {
    //         /* rates arrays contains the parent class we're looking for */
    //         excludeFilter.push(key);
    //         // Add Child vehicle object to Include Child Filter
    //         if(!_.isNil(includeChildFilter[key])) {
    //             _.set(includeChildFilter, key, sactionedCars[key])
    //         }
    //     }
    // })

    // Old Logic
    let includeChildFilter = {}

    _.each(sactionedCars, (car, key) => {
        if (car.parent_class != '' || car.parent_class != null) {
            if (excludeFilter.indexOf(car.parent_class) < 0) {
                if (excludeFilter.indexOf(key) < 0) {
                    //Check Parent/Child Bundles
                    if (
                        (quotation.mode == 'default' &&
                            quotation.trips[quotation.trip].promo.trim() == '' &&
                            fullcoverblackoutdates(key) &&
                            fullcoverwhitelistlocation(key).indexOf(pickupLoc.class) != -1 &&
                            car.bundled_cover == '1' &&
                            rentaldays <= parseInt(car.bundled_max_days)) ||
                        (fullcoverblackoutdates(car.parent_class) &&
                            fullcoverwhitelistlocation(car.parent_class).indexOf(pickupLoc.class) !=
                                -1 &&
                            sactionedCars[car.parent_class].bundled_cover == '1' &&
                            rentaldays <=
                                parseInt(sactionedCars[car.parent_class].bundled_max_days))
                    ) {
                        // Ignore
                    } else {
                        if (currentRates.filter(r => r.Class === car.parent_class).length > 0) {
                            /* rates arrays contains the parent class we're looking for */
                            excludeFilter.push(key)
                            // Add Child vehicle object to Include Child Filter
                            if (typeof includeChildFilter[key] == 'undefined') {
                                includeChildFilter[key] = sactionedCars[key]
                            }
                        }
                    }
                }
            }
        }
    })

    // New Lodash
    // const childItems = _
    // .chain({})
    // .merge(!_.isEmpty(includeChildFilter)? currentRates : {})
    // .filter( item => {
    //     return !_.isNil(includeChildFilter[item.Class])
    //     && quotation.relocationID > 0
    //         ? (_.includes(quotation.relocationCars, item.Class))
    //         : ((_.has(sactionedCars,`${item.Class}`) &&  _.includes(filters, sactionedCars[item.Class].vehicle_type)) || filters.length == 0 )
    // })
    // .orderBy([item => parseFloat(item.RateOnlyEstimate )], ['desc'])
    // .value();

    // Old Logic
    let childItems = {}
    if (Object.keys(includeChildFilter).length > 0) {
        currentRates
            .filter(itemC => {
                if (typeof includeChildFilter[itemC.Class] == 'undefined') {
                    return false
                } else {
                    return quotation.relocationID > 0
                        ? quotation.relocationCars.indexOf(itemC.Class) >= 0 &&
                              !_.isEmpty(quotation.relocationCars)
                        : (sactionedCars[itemC.Class] &&
                              filters.indexOf(sactionedCars[itemC.Class].vehicle_type) >= 0) ||
                              filters.length == 0
                }
            })
            .sort((aC, bC) => {
                if (
                    typeof aC == 'object' &&
                    aC.hasOwnProperty('Class') &&
                    typeof bC == 'object' &&
                    bC.hasOwnProperty('Class')
                ) {
                    if (
                        typeof sactionedCars[aC.Class] !== 'undefined' &&
                        typeof sactionedCars[bC.Class] !== 'undefined'
                    ) {
                        if (
                            (quotation.mode == 'default' &&
                                fullcoverblackoutdates(aC.Class) &&
                                fullcoverwhitelistlocation(aC.Class).indexOf(pickupLoc.class) !=
                                    -1 &&
                                sactionedCars[aC.Class].bundled_cover == '1' &&
                                rentaldays <= parseInt(sactionedCars[aC.Class].bundled_max_days) &&
                                quotation.trips[quotation.trip].promo.trim() == '') ||
                            (quotation.mode == 'default' &&
                                fullcoverblackoutdates(bC.Class) &&
                                fullcoverwhitelistlocation(bC.Class).indexOf(pickupLoc.class) !=
                                    -1 &&
                                sactionedCars[bC.Class].bundled_cover == '1' &&
                                rentaldays <= parseInt(sactionedCars[bC.Class].bundled_max_days))
                        ) {
                            return (
                                (sactionedCars[bC.Class].bundled_cover == '1') -
                                (sactionedCars[aC.Class].bundled_cover == '1')
                            )
                        }
                    }
                }
                if (
                    typeof aC == 'object' &&
                    aC.hasOwnProperty('Availability') &&
                    aC.hasOwnProperty('RateOnlyEstimate') &&
                    typeof bC == 'object' &&
                    bC.hasOwnProperty('Availability') &&
                    bC.hasOwnProperty('RateOnlyEstimate')
                ) {
                    if (sort == 'recommended') {
                        return (
                            (aC.Availability != 'Available') - (bC.Availability != 'Available') ||
                            aC.RateOnlyEstimate - (bC.Class == 'ZA' ? 9999 : bC.RateOnlyEstimate)
                        )
                    } else if (sort == 'pricehl') {
                        return (
                            (aC.Availability != 'Available') - (bC.Availability != 'Available') ||
                            bC.RateOnlyEstimate - aC.RateOnlyEstimate
                        )
                    } else {
                        return (
                            (aC.Availability != 'Available') - (bC.Availability != 'Available') ||
                            aC.RateOnlyEstimate - bC.RateOnlyEstimate
                        )
                    }
                }
            })
            .map((itemC, indexC) => {
                childItems[itemC.Class] = itemC
            })
    }

    // New Lodash
    // const desktopView = _
    // .chain({})
    // .merge(currentRates)
    // .filter( item => {
    //     return !_.includes(excludeFilter,item.Class)
    //     && quotation.relocationID > 0
    //     ? (quotation.relocationCars.indexOf(item.Class) >=0 && !_.isEmpty(quotation.relocationCars))
    //     : ((_.has(sactionedCars,`${item.Class}`) &&  _.includes(filters, sactionedCars[item.Class].vehicle_type)) || filters.length == 0 )
    // })
    // .orderBy(item => parseFloat( item.RateOnlyEstimate ), 'asc')
    // .orderBy(item => item.Availability, 'asc')
    // .value()

    // Old Logic
    const desktopView = currentRates
        .filter(item => {
            if (excludeFilter.indexOf(item.Class) >= 0) {
                return false
            } else {
                return quotation.relocationID > 0
                    ? quotation.relocationCars.indexOf(item.Class) >= 0 &&
                          !_.isEmpty(quotation.relocationCars)
                    : (sactionedCars[item.Class] &&
                          filters.indexOf(sactionedCars[item.Class].vehicle_type) >= 0) ||
                          filters.length == 0
            }
        })
        .sort((a, b) => {
            if (
                typeof a == 'object' &&
                a.hasOwnProperty('Class') &&
                typeof b == 'object' &&
                b.hasOwnProperty('Class')
            ) {
                if (
                    typeof sactionedCars[a.Class] !== 'undefined' &&
                    typeof sactionedCars[b.Class] !== 'undefined'
                ) {
                    if (
                        (quotation.mode == 'default' &&
                            fullcoverblackoutdates(a.Class) &&
                            fullcoverwhitelistlocation(a.Class).indexOf(pickupLoc.class) != -1 &&
                            sactionedCars[a.Class].bundled_cover == '1' &&
                            rentaldays <= parseInt(sactionedCars[a.Class].bundled_max_days) &&
                            quotation.trips[quotation.trip].promo.trim() == '') ||
                        (quotation.mode == 'default' &&
                            fullcoverblackoutdates(b.Class) &&
                            fullcoverwhitelistlocation(b.Class).indexOf(pickupLoc.class) != -1 &&
                            sactionedCars[b.Class].bundled_cover == '1' &&
                            rentaldays <= parseInt(sactionedCars[b.Class].bundled_max_days))
                    ) {
                        return (
                            (sactionedCars[b.Class].bundled_cover == '1') -
                            (sactionedCars[a.Class].bundled_cover == '1')
                        )
                    }
                }
            }
            if (
                typeof a == 'object' &&
                a.hasOwnProperty('Availability') &&
                a.hasOwnProperty('RateOnlyEstimate') &&
                typeof b == 'object' &&
                b.hasOwnProperty('Availability') &&
                b.hasOwnProperty('RateOnlyEstimate')
            ) {
                if (sort == 'recommended') {
                    return (
                        (a.Availability != 'Available') - (b.Availability != 'Available') ||
                        a.RateOnlyEstimate - (b.Class == 'ZA' ? 9999 : b.RateOnlyEstimate)
                    )
                } else if (sort == 'pricehl') {
                    return (
                        (a.Availability != 'Available') - (b.Availability != 'Available') ||
                        b.RateOnlyEstimate - a.RateOnlyEstimate
                    )
                } else {
                    return (
                        (a.Availability != 'Available') - (b.Availability != 'Available') ||
                        a.RateOnlyEstimate - b.RateOnlyEstimate
                    )
                }
            }
        })

    // New Lodash
    // const mobileView = _
    // .chain({})
    // .merge(!(mobileExcludeFilter.length === excludeFilter.length && mobileExcludeFilter.sort().every(function(value, index) { return value === excludeFilter.sort()[index]}))
    //     ? currentRates
    //     : {}
    // )
    // .filter( item => {
    //     return (quotation.mode== 'default'
    //         && fullcoverblackoutdates(item.Class)
    //         && _.includes(fullcoverwhitelistlocation(item.Class),pickupLoc.class)
    //         && sactionedCars[item.Class].bundled_cover=='1'
    //         && rentaldays<=parseInt(sactionedCars[item.Class].bundled_max_days)
    //         && currentTrip.promo.trim() == ''
    //     )
    // })
    // .orderBy(item => sactionedCars[b.Class].bundled_cover=='1'? 1:0,'asc')
    // .value()

    // Old Logic
    const mobileView = currentRates
        .filter(item => {
            if (mobileExcludeFilter.indexOf(item.Class) >= 0) {
                return false
            } else {
                return quotation.relocationID > 0
                    ? quotation.relocationCars.indexOf(item.Class) >= 0 &&
                          !_.isEmpty(quotation.relocationCars)
                    : (sactionedCars[item.Class] &&
                          filters.indexOf(sactionedCars[item.Class].vehicle_type) >= 0) ||
                          filters.length == 0
            }
        })
        .sort((a, b) => {
            if (
                typeof a == 'object' &&
                a.hasOwnProperty('Class') &&
                typeof b == 'object' &&
                b.hasOwnProperty('Class')
            ) {
                if (
                    typeof sactionedCars[a.Class] !== 'undefined' &&
                    typeof sactionedCars[b.Class] !== 'undefined'
                ) {
                    if (
                        (quotation.mode == 'default' &&
                            fullcoverblackoutdates(a.Class) &&
                            fullcoverwhitelistlocation(a.Class).indexOf(pickupLoc.class) != -1 &&
                            sactionedCars[a.Class].bundled_cover == '1' &&
                            rentaldays <= parseInt(sactionedCars[a.Class].bundled_max_days) &&
                            quotation.trips[quotation.trip].promo.trim() == '') ||
                        (quotation.mode == 'default' &&
                            fullcoverblackoutdates(b.Class) &&
                            fullcoverwhitelistlocation(b.Class).indexOf(pickupLoc.class) != -1 &&
                            sactionedCars[b.Class].bundled_cover == '1' &&
                            rentaldays <= parseInt(sactionedCars[b.Class].bundled_max_days))
                    ) {
                        return (
                            (sactionedCars[b.Class].bundled_cover == '1') -
                            (sactionedCars[a.Class].bundled_cover == '1')
                        )
                    }
                }
            }
            if (
                typeof a == 'object' &&
                a.hasOwnProperty('Availability') &&
                a.hasOwnProperty('RateOnlyEstimate') &&
                typeof b == 'object' &&
                b.hasOwnProperty('Availability') &&
                b.hasOwnProperty('RateOnlyEstimate')
            ) {
                if (sort == 'recommended') {
                    return (
                        (a.Availability != 'Available') - (b.Availability != 'Available') ||
                        a.RateOnlyEstimate - (b.Class == 'ZA' ? 9999 : b.RateOnlyEstimate)
                    )
                } else if (sort == 'pricehl') {
                    return (
                        (a.Availability != 'Available') - (b.Availability != 'Available') ||
                        b.RateOnlyEstimate - a.RateOnlyEstimate
                    )
                } else {
                    return (
                        (a.Availability != 'Available') - (b.Availability != 'Available') ||
                        a.RateOnlyEstimate - b.RateOnlyEstimate
                    )
                }
            }
        })

    return {
        excludeFilter,
        mobileExcludeFilter,
        includeChildFilter,
        childItems,
        desktopView,
        mobileView,
    }
}
