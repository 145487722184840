// Set up your root reducer here...
import { connectRouter } from 'connected-react-router'
import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import appReducer from './appReducer'
import quotationReducer from './quotationReducer'
import modelsReducer from './modelsReducer'

/* Redux-Persist */
const presistedRootReducer = history =>
    persistCombineReducers(
        {
            key: 'root',
            whitelist: ['app', 'quotation', 'rates', 'models'],
            storage,
        },
        {
            router: connectRouter(history),
            app: appReducer,
            quotation: quotationReducer,
            models: modelsReducer,
        },
    )

export default presistedRootReducer
