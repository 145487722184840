/* eslint-disable import/no-named-as-default */
import React from 'react'
import { hot } from 'react-hot-loader'
import { ThemeProvider } from 'react-jss'

import { theme } from './ui/theme'

import App from './App'

import NotificationProvider from './Notification'

class Layout extends React.Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <NotificationProvider>
                    <App />
                </NotificationProvider>
            </ThemeProvider>
        )
    }
}

export default hot(module)(Layout)
