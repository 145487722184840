import { select, takeLatest } from 'redux-saga/effects'
import moment from 'moment'
import _ from 'lodash'
import numeral from 'numeral'

import * as listRules from '../components/rules/list'
import * as coversRules from '../components/rules/covers'
import * as extrasRules from '../components/rules/extras'
import * as paymentRules from '../components/rules/payment'

const createCustomDimensions = quotation => {
    const currentTrip = quotation.trips[quotation.trip]
    const fromDateTime = moment(currentTrip.from, 'YYYY-MM-DDTHH:mm:ss')
    const untilDateTime = moment(currentTrip.until, 'YYYY-MM-DDTHH:mm:ss')
    const days = untilDateTime.diff(fromDateTime, 'days')

    return {
        dimension1: days,
        metric1: days,
        dimension2: currentTrip.pickup,
        dimension3: fromDateTime.format('DD, MMM, YYYY, hh:mm a'),
        dimension4: currentTrip.return,
        dimension5: untilDateTime.format('DD, MMM, YYYY, hh:mm a'),
        dimension6: currentTrip.dob,
        dimension7: currentTrip.promo,
    }
}

const triggerEvent = (category, action, label, value) => {
    !_.isNil(window.dataLayer) &&
        window.dataLayer.push({
            event: 'AceRentalsEvent.ec',
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
            eventValue: value,
            eventCallback: () => {},
        })
}

const triggerImpressions = (impressions, currencyCode) => {
    !_.isNil(window.dataLayer) &&
        window.dataLayer.push({
            ecommerce: {
                currencyCode: currencyCode,
                impressions: impressions,
            },
            event: 'AceRentalsPageView.ec',
            eventCategory: 'Ecommerce',
            eventAction: 'gtm.dom',
            eventCallback: () => {},
        })
}

const triggerClick = (product, page) => {
    !_.isNil(window.dataLayer) &&
        window.dataLayer.push({
            ecommerce: {
                click: {
                    actionField: { list: page },
                    products: [product],
                },
            },
            event: 'AceRentalsEvent.ec',
            eventCategory: 'Ecommerce',
            eventAction: 'productClick',
            eventCallback: () => {},
        })
}

const triggerAddToCart = (product, currencyCode) => {
    !_.isNil(window.dataLayer) &&
        window.dataLayer.push({
            ecommerce: {
                currencyCode: currencyCode,
                add: {
                    products: [product],
                },
            },
            event: 'AceRentalsEvent.ec',
            eventCategory: 'Ecommerce',
            eventAction: 'addToCart',
            eventCallback: () => {},
        })
}

const triggerCheckout = (products, step) => {
    !_.isNil(window.dataLayer) &&
        window.dataLayer.push({
            ecommerce: {
                checkout: {
                    actionField: { step: step },
                    products: products,
                },
            },
            event: 'AceRentalsEvent.ec',
            eventCategory: 'Ecommerce',
            eventAction: 'Checkout',
            eventCallback: () => {},
        })
}

const triggerPurchase = (id, affiliation, revenue, tax, shipping, coupon, products) => {
    !_.isNil(window.dataLayer) &&
        window.dataLayer.push({
            ecommerce: {
                purchase: {
                    actionField: { id, affiliation, revenue, tax, shipping, coupon },
                    products: products,
                },
            },
            event: 'AceRentalsEvent.ec',
            eventCategory: 'Ecommerce',
            eventAction: 'purchase',
            eventCallback: () => {},
        })
}

const createImpression = (product, position, list) => {
    return { ...product, position, list }
}

const createProduct = (customDimensions, id, name, brand, category, variant, price, quantity) => {
    return { ...customDimensions, id, name, brand, category, variant, price, quantity }
}

export function* sendGATracking(params) {
    const quotation = yield select(state => ({ ...state.quotation }))
    if (quotation.mode == 'checkin') return true

    const models = yield select(state => ({ ...state.models }))
    const currentTrip = quotation.trips[quotation.trip]
    const { context } = params
    const customDimensions = createCustomDimensions(quotation, models)

    let impressions = []
    let products = []

    //derive from list denegrate instead of just sactionRates
    let { currentRates } = listRules.sanctionRates(quotation, models, context)

    let covers = []
    let options = []
    let selectedRate = []
    let selectedCover = []
    let listPath = ''

    switch (params.step) {
        case 'Search Result':
            listPath = 'bookings/searchresult (' + customDimensions.dimension2 + ')'

            currentRates.map((item, i) => {
                const product = createProduct(
                    customDimensions,
                    item.Class,
                    models.vehicles[item.Class] ? models.vehicles[item.Class].name : item.Class,
                    models.vehicles[item.Class]
                        ? models.vehicles[item.Class].description
                        : item.Class,
                    (quotation.mode == 'default' ? '' : quotation.mode) + listPath,
                    'Vehicle',
                    numeral(item.Estimate).format('0.00'),
                    1, // always set qty = 1 for vehicle
                )

                products.push(product)
                impressions.push(createImpression(product, i + 1, listPath))

                // Availability Search
                triggerEvent(
                    'Availability Search',
                    `${customDimensions.dimension3} - ${customDimensions.dimension5} ( ${customDimensions.dimension1}d ) / ${customDimensions.dimension2} - ${customDimensions.dimension4} / ${customDimensions.dimension6} / ${customDimensions.dimension7}`,
                    `${item.Class} / ( ${item.RateOnlyEstimate / customDimensions.dimension1}pd ) ${
                        item.RateOnlyEstimate
                    } / ${item.Availability}`,
                    +item.Availability == 'Available' ? 1 : 0,
                )
            })

            // Search Results Impressions
            triggerImpressions(impressions, context.currency)

            // Checkout Step 1
            triggerCheckout(products, 1)

            break
        case 'Insurance and Extras':
            listPath = 'bookings/create (' + customDimensions.dimension2 + ')'
            covers = coversRules.sanctionCovers(quotation, models, context)
            options = extrasRules.sanctionOptions(quotation, models, context)

            covers.coverItems.map((item, i) => {
                const product = createProduct(
                    customDimensions,
                    item.id,
                    item.title,
                    item.id,
                    (quotation.mode == 'default' ? '' : quotation.mode) + listPath,
                    'Insurance',
                    numeral(item.cost).format('0.00'),
                    item.rate_type == 'rental days' ? parseInt(customDimensions.dimension1) : 1,
                )

                products.push(product)
                impressions.push(createImpression(product, i + 1, listPath))
            })

            options.map((item, i) => {
                const product = createProduct(
                    customDimensions,
                    item['@attributes'].code,
                    item['@attributes'].desc,
                    item['@attributes'].code,
                    (quotation.mode == 'default' ? '' : quotation.mode) + listPath,
                    'Optional Extras',
                    numeral(item['@attributes'].rate).format('0.00'),
                    item['@attributes'].rate_type == 'rental days'
                        ? parseInt(customDimensions.dimension1)
                        : 1,
                )

                products.push(product)
                impressions.push(createImpression(product, i + 1, listPath))
            })

            selectedRate = _.find(currentRates, { Class: currentTrip.car })
            const selectedCar = [
                createProduct(
                    customDimensions,
                    selectedRate.Class,
                    models.vehicles[selectedRate.Class]
                        ? models.vehicles[selectedRate.Class].name
                        : selectedRate.Class,
                    models.vehicles[selectedRate.Class]
                        ? models.vehicles[selectedRate.Class].description
                        : selectedRate.Class,
                    (quotation.mode == 'default' ? '' : quotation.mode) + listPath,
                    'Vehicle',
                    numeral(selectedRate.Estimate).format('0.00'),
                    1, // always set qty = 1 for vehicle
                ),
            ]

            // Selected Car Click
            triggerClick(selectedCar, listPath)

            // Selected Car Add To Cart
            triggerAddToCart(selectedCar, context.currency)

            // Covers and Extras Impressions
            triggerImpressions(impressions, context.currency)

            // Checkout Step 2
            triggerCheckout(products, 2)

            break
        case 'Personal Details':
            listPath = 'bookings/create (' + customDimensions.dimension2 + ')'
            covers = coversRules.sanctionCovers(quotation, models, context)
            options = extrasRules.sanctionOptions(quotation, models, context)

            selectedCover = covers.selectedCover
            products.push(
                createProduct(
                    customDimensions,
                    selectedCover.id,
                    selectedCover.title,
                    selectedCover.id,
                    (quotation.mode == 'default' ? '' : quotation.mode) + listPath,
                    'Insurance',
                    numeral(selectedCover.cost).format('0.00'),
                    selectedCover.rate_type == 'rental days'
                        ? parseInt(customDimensions.dimension1)
                        : 1,
                ),
            )

            currentTrip.extras.map(item => {
                const opt = _.find(options, o => o['@attributes'].code == item.code)
                products.push(
                    createProduct(
                        customDimensions,
                        opt['@attributes'].code,
                        opt['@attributes'].desc,
                        opt['@attributes'].code,
                        (quotation.mode == 'default' ? '' : quotation.mode) + listPath,
                        'Optional Extras',
                        numeral(opt['@attributes'].rate).format('0.00'),
                        opt['@attributes'].rate_type == 'rental days'
                            ? parseInt(customDimensions.dimension1)
                            : item.qty,
                    ),
                )
            })

            // Selected Cover and Extras Click
            triggerClick(products, listPath)

            // Selected Cover and Extras Add To Cart
            triggerAddToCart(products, context.currency)

            // Checkout Step 3
            triggerCheckout(products, 3)

            break
        case 'Payment':
            listPath = 'bookings/create (' + customDimensions.dimension2 + ')'
            covers = coversRules.sanctionCovers(quotation, models, context)
            options = extrasRules.sanctionOptions(quotation, models, context)

            selectedCover = covers.selectedCover
            products.push(
                createProduct(
                    customDimensions,
                    selectedCover.id,
                    selectedCover.title,
                    selectedCover.id,
                    (quotation.mode == 'default' ? '' : quotation.mode) + listPath,
                    'Insurance',
                    numeral(selectedCover.cost).format('0.00'),
                    selectedCover.rate_type == 'rental days'
                        ? parseInt(customDimensions.dimension1)
                        : 1,
                ),
            )

            currentTrip.extras.map(item => {
                const opt = _.find(options, o => o['@attributes'].code == item.code)
                products.push(
                    createProduct(
                        customDimensions,
                        opt['@attributes'].code,
                        opt['@attributes'].desc,
                        opt['@attributes'].code,
                        (quotation.mode == 'default' ? '' : quotation.mode) + listPath,
                        'Optional Extras',
                        numeral(opt['@attributes'].rate).format('0.00'),
                        opt['@attributes'].rate_type == 'rental days'
                            ? parseInt(customDimensions.dimension1)
                            : item.qty,
                    ),
                )
            })

            // Checkout Step 4
            triggerCheckout(products, 4)
            break
        case 'Complete':
            listPath = 'bookings/create (' + customDimensions.dimension2 + ')'
            covers = coversRules.sanctionCovers(quotation, models, context)
            options = extrasRules.sanctionOptions(quotation, models, context)
            const costBreakdown = paymentRules.getCostBreakdown(quotation, models, context)

            selectedRate = _.find(currentRates, { Class: currentTrip.car })
            products.push(
                createProduct(
                    customDimensions,
                    selectedRate.Class,
                    models.vehicles[selectedRate.Class]
                        ? models.vehicles[selectedRate.Class].name
                        : selectedRate.Class,
                    models.vehicles[selectedRate.Class]
                        ? models.vehicles[selectedRate.Class].description
                        : selectedRate.Class,
                    (quotation.mode == 'default' ? '' : quotation.mode) + listPath,
                    'Vehicle',
                    numeral(selectedRate.Estimate).format('0.00'),
                    1, // always set qty = 1 for vehicle
                ),
            )

            selectedCover = covers.selectedCover
            products.push(
                createProduct(
                    customDimensions,
                    selectedCover.id,
                    selectedCover.title,
                    selectedCover.id,
                    (quotation.mode == 'default' ? '' : quotation.mode) + listPath,
                    'Insurance',
                    numeral(selectedCover.cost).format('0.00'),
                    selectedCover.rate_type == 'rental days'
                        ? parseInt(customDimensions.dimension1)
                        : 1,
                ),
            )

            currentTrip.extras.map(item => {
                const opt = _.find(options, o => o['@attributes'].code == item.code)
                products.push(
                    createProduct(
                        customDimensions,
                        opt['@attributes'].code,
                        opt['@attributes'].desc,
                        opt['@attributes'].code,
                        (quotation.mode == 'default' ? '' : quotation.mode) + listPath,
                        'Optional Extras',
                        numeral(opt['@attributes'].rate).format('0.00'),
                        opt['@attributes'].rate_type == 'rental days'
                            ? parseInt(customDimensions.dimension1)
                            : item.qty,
                    ),
                )
            })

            // Checkout Step 5
            triggerCheckout(products, 5)

            // Purchase
            triggerPurchase(
                currentTrip.res,
                'Online Booking 3.0',
                numeral(costBreakdown.total).format('0.00'),
                numeral(costBreakdown.total * context.gst).format('0.00'),
                '0.00',
                currentTrip.promo, //promocode
                products,
            )
            break
        default:
            break
    }
}

export function* watchAnalyticsRequest() {
    yield takeLatest('sendGATracking', sendGATracking)
}
