import initialState from './initialState'
import _ from 'lodash'

export default function modelsReducer(state = initialState.models, action) {
    switch (action.type) {
        case 'getCMSLocations.ok':
            return {
                ...state,
                locations: {
                    ...action.response,
                },
            }
        case 'getCMSVehicles.ok':
            return {
                ...state,
                vehicles: _.transform(
                    [...action.response],
                    (result, value) => {
                        result[value.class] = value
                    },
                    {},
                ),
            }
        case 'getCMSOptions.ok':
            return {
                ...state,
                options: {
                    ...action.response,
                },
            }
        case 'getCMSCovers.ok':
            return {
                ...state,
                covers: _.transform(
                    [...action.response],
                    (result, value) => {
                        ;(result[value.mode] || (result[value.mode] = [])).push(value)
                    },
                    {},
                ),
            }
        case 'getCMSLocales.ok':
            return {
                ...state,
                locales: {
                    ...action.response[0],
                },
            }
        case 'getCMSPartners.ok':
            return {
                ...state,
                partners: {
                    ...action.response,
                },
            }
        case 'getCMSRegions.ok':
            return {
                ...state,
                regions: [...action.response],
            }
        case 'getCMSAges.ok':
            return {
                ...state,
                ages: [...action.response],
            }
        case 'getCMSSwitchboards.ok':
            return {
                ...state,
                switchboards: {
                    ...action.response[0],
                },
            }
        case 'clearPromocode': {
            return {
                ...state,
                promocode: {},
            }
        }
        case 'checkPromocode.ok':
            return {
                ...state,
                promocode: {
                    ...action.response,
                },
            }
        case 'checkRelocation.ok':
            return {
                ...state,
                relocation: {
                    ...action.response,
                },
            }
        default:
            return state
    }
}
