const bezier = 'cubic-bezier(0.62, 0.28, 0.23, 0.99)'
const timing = '250ms'
const fontFamily = `"Roboto", "Franklin Gothic Medium", Tahoma, sans-serif`

export const theme = {
    fontFamily,
    animations: {
        bezier,
        timing,
        animate: `${timing} ${bezier}`,
    },
    headings: {
        two: {
            fontFamily,
            fontWeight: 900,
            fontSize: 22,
            letterSpacing: '1.1px',
            lineHeight: '27px',
            '@media (max-width: 600px)': {
                fontSize: 20,
                letterSpacing: '.5px',
                lineHeight: 1.35,
            },
        },
        three: {
            fontFamily,
            fontSize: 20,
            fontWeight: 'bold',
            lineHeight: 'normal',
            letterSpacing: '0.31px',
        },
        five: {
            fontFamily,
            fontWeight: 900,
            fontSize: '13px',
            letterSpacing: '1.3px',
            lineHeight: 'normal',
        },
        carFeaturesHeading: {
            fontFamily,
            fontWeight: 900,
            fontSize: '23px',
            letterSpacing: '1.2px',
            lineHeight: 'normal',
        },
        carFeaturesButtonToggle: {
            fontFamily,
            fontWeight: 'bold',
            fontSize: '16px',
            letterSpacing: '1px',
            lineHeight: 'normal',
        },
        bookingWidgetSelectsTitle: {
            fontFamily,
            fontWeight: 'bold',
            fontSize: '16px',
            letterSpacing: '1.23px',
            lineHeight: 'normal',
        },
        bookingTitleBarHeading: {
            fontFamily,
            fontWeight: 'bold',
            fontSize: '22px',
            letterSpacing: '1px',
            lineHeight: 'normal',
            textTransform: 'uppercase',
        },
    },
    paragraphs: {
        one: {
            fontFamily,
            fontSize: '16px',
            letterSpacing: 0,
            lineHeight: '25px',
        },
        carFeaturesSubtitle: {
            fontFamily,
            fontSize: '16px',
            fontWeight: 300,
            letterSpacing: '0.5px',
            lineHeight: 'normal',
        },
        carFeaturesSubSubtitle: {
            fontFamily,
            fontSize: '16px',
            fontWeight: 300,
            letterSpacing: '1px',
            lineHeight: 'normal',
        },
        summaryTextStyle: {
            fontFamily,
            fontSize: '15px',
            fontWeight: 300,
            letterSpacing: '0.7px',
            lineHeight: 'normal',
        },
        summaryPriceStyle: {
            fontFamily,
            fontSize: '45px',
            fontWeight: 300,
            letterSpacing: '2.1px',
            lineHeight: 'normal',
        },
        bookingSelectTextStyle: {
            fontFamily,
            fontSize: 13,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.81px',
        },
    },
    colors: {
        black: '#000',
        yellow: '#f6c414',
        sunflower: '#fedc02',
        pineapple: '#FEDC04',
        blue: '#01396c',
        lightblue: '#0056a7',
        lightestblue: '#f0f4f5',
        marine: '#00376C',
        ocean: '#02386b',
        deepsea: '#004e95',
        white: '#fff',
        whiteTwo: '#e0e0e0',
        whiteThree: '#eaeaea',
        eggshell: '#f3f6f7',
        creamy: '#fff3a7',
        slide: '#9D9D9D',
        silver: '#e4e4e4',
        stormcloud: '#6f6f6f',
        sunshine: '#f6c413',
        lightgrey: '#E5EAEF',
        darkgrey: '#333338',
        darkergrey: '#1F2833',
        brushedsteel: '#424b5a',
        coal: '#383838',
        shade: '#363636',
        absoluteBlack: '#000000',
        cloudyBlue: '#bbcad8',
        irishGreen: '#009520',
        warmGrey: '#6f6f6f',
        // silver: "#dce3e4",
        pinkishGrey: '#c4c4c4',
        warmGreyTwo: '#848383',
        lightTan: '#fcebb0',
        buff: '#fff3a7',
    },
    borderRadius: 7,
    bookings: {
        lhsBoxSizing: 'border-box',
        lhsPaddingRight: 16,
        lhsWidth: 944,
        rhsBoxSizing: 'border-box',
        rhsPaddingLeft: 23,
        rhsPaddingRight: 24,
        rhsWidth: 433,
    },
    wrappers: {
        column: '119px',
        columnGap: '1px',
        innerWidthRaw: 1440,
        innerWidth: '1440px',
        maxWidth: '1920px',
        bookingsLayoutWrapper: {},
        small: {
            boxSizing: 'content-box',
            fontFamily: fontFamily,
            margin: '0 auto',
            maxWidth: 119 * 8 + 1 * 9, // 8 columns wide, with 9 gaps
            padding: '0 25px',
        },
        standardDesign: {
            boxSizing: 'border-box',
            fontFamily: fontFamily,
            margin: '0 auto',
            maxWidth: '1250px', // A prominent wrapper size in the design is 1200px
            padding: '0 25px',
        },
        standard: {
            boxSizing: 'border-box',
            fontFamily: fontFamily,
            margin: '0 auto',
            maxWidth: 1440 + 50, // Standard inner wrapper width
            padding: '0 25px',
            '@media (max-width: 600px)': {
                padding: '0 10px',
            },
        },
        large: {
            boxSizing: 'border-box',
            fontFamily: fontFamily,
            margin: '0 auto',
            maxWidth: '1920px',
        },
        flexCenterCenter: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
        },
        flexCenterSpaceBetween: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
        },
    },

    breakpoints: {
        extrasCardsStack: 1049,
    },
}
