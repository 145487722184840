import { takeLatest, put, call } from 'redux-saga/effects'
import { callModelData } from './helpers'

const getConfig = params => {
    return {
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(params),
    }
}

export function* getCMSLocations() {
    try {
        yield call(callModelData, () => fetch('/cms/locations'), 'getCMSLocations')
    } catch (error) {
        yield put({ type: 'getCMSLocations.ex', error: error })
    }
}

export function* getCMSVehicles() {
    try {
        yield call(callModelData, () => fetch('/cms/vehicles'), 'getCMSVehicles')
    } catch (error) {
        yield put({ type: 'getCMSVehicles.ex', error: error })
    }
}

export function* getCMSOptions() {
    try {
        yield call(callModelData, () => fetch('/cms/options'), 'getCMSOptions')
    } catch (error) {
        yield put({ type: 'getCMSOptions.ex', error: error })
    }
}

export function* getCMSCovers() {
    try {
        yield call(callModelData, () => fetch('/cms/covers'), 'getCMSCovers')
    } catch (error) {
        yield put({ type: 'getCMSCovers.ex', error: error })
    }
}

export function* getCMSLocales() {
    try {
        yield call(callModelData, () => fetch('/cms/locales'), 'getCMSLocales')
    } catch (error) {
        yield put({ type: 'getCMSLocales.ex', error: error })
    }
}

export function* getCMSPartners() {
    try {
        yield call(callModelData, () => fetch('/cms/partners'), 'getCMSPartners')
    } catch (error) {
        yield put({ type: 'getCMSPartners.ex', error: error })
    }
}

export function* getCMSRegions() {
    try {
        yield call(callModelData, () => fetch('/cms/regions'), 'getCMSRegions')
    } catch (error) {
        yield put({ type: 'getCMSRegions.ex', error: error })
    }
}

export function* getCMSAges() {
    try {
        yield call(callModelData, () => fetch('/cms/ages'), 'getCMSAges')
    } catch (error) {
        yield put({ type: 'getCMSAges.ex', error: error })
    }
}

export function* getCMSSwitchboards() {
    try {
        yield call(callModelData, () => fetch('/cms/switchboards'), 'getCMSSwitchboards')
    } catch (error) {
        yield put({ type: 'getCMSSwitchboards.ex', error: error })
    }
}

export function* checkPromocode(params) {
    try {
        yield call(
            callModelData,
            () => fetch('/cms/checkpromocode', getConfig(params)),
            'checkPromocode',
        )
    } catch (error) {
        yield put({ type: 'checkPromocode.ex', error: error })
    }
}

export function* checkRelocation(params) {
    try {
        yield call(
            callModelData,
            () => fetch('/cms/checkrelocation', getConfig(params)),
            'checkRelocation',
        )
    } catch (error) {
        yield put({ type: 'checkRelocation.ex', error: error })
    }
}

export function* watchModelsRequest() {
    yield takeLatest('getCMSLocations', getCMSLocations)
    yield takeLatest('getCMSVehicles', getCMSVehicles)
    yield takeLatest('getCMSOptions', getCMSOptions)
    yield takeLatest('getCMSCovers', getCMSCovers)
    yield takeLatest('getCMSLocales', getCMSLocales)
    yield takeLatest('getCMSPartners', getCMSPartners)
    yield takeLatest('getCMSRegions', getCMSRegions)
    yield takeLatest('getCMSAges', getCMSAges)
    yield takeLatest('getCMSSwitchboards', getCMSSwitchboards)
    yield takeLatest('checkPromocode', checkPromocode)
    yield takeLatest('checkRelocation', checkRelocation)
}
