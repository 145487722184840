/* eslint-disable react/prop-types */
/* eslint-disable import/no-named-as-default */
import React, { Fragment, useEffect, useState, Suspense, lazy } from 'react'
import { Link, Route, Switch, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import Context, { demystify } from './Context'
import _ from 'lodash'
import moment from 'moment'

const Itinerary = lazy(() => import('./containers/Itinerary'))
const Checkin = lazy(() => import('./containers/Checkin'))
const SearchResult = lazy(() => import('./containers/SearchResult'))
const Create = lazy(() => import('./containers/Create'))
const Confirmation = lazy(() => import('./containers/Confirmation'))
const LocationIndex = lazy(() => import('./ui/Locations/LocationIndex'))
// const NotFound = lazy(() => import('./containers/NotFound'))

const SanitiseErrors = (errmsg, rates = []) => {
  if (errmsg.indexOf('LT:') != -1) {
    const hr = errmsg.match(/LT(:\d)H/)
    return `The Selected location requires ${hr[1]} hour/s lead time for pickup`
  } else {
    const mindays = errmsg.match(/(\d+) Day\(2061\)/)
    if (mindays) {
      const availability = _.filter(rates, r => r.Availability == 'Available')
      if (_.isEmpty(availability)) {
        return `There is a minimum rental length of ${mindays[1]} days on some vehicles, please change your date selection`
      } else {
        return ''
      }
    } else {
      return errmsg
    }
  }
}

const App = props => {
  const dispatch = useDispatch()
  const app = useSelector(state => ({ ...state.app }))
  const quotation = useSelector(state => ({ ...state.quotation }))
  const currentTrip = quotation.trips[quotation.trip]
  const currentRate = currentTrip.rates[quotation.mode]
  const { addToast } = useToasts()

  useEffect(() => {
    if (_.isArray(app.messages)) {
      app.messages.map(m => {
        const err = SanitiseErrors(m.Text, currentRate)
        if (err != '') {
          addToast(err, {
            appearance: m['@attributes'].type.toLowerCase(),
            autoDismiss: false,
          })
        }
      })
    } else {
      if (!_.isEmpty(app.messages)) {
        const err = SanitiseErrors(app.messages.Text, currentRate)
        if (err != '') {
          addToast(err, {
            appearance: app.messages['@attributes'].type.toLowerCase(),
            autoDismiss: false,
          })
        }
      }
    }

    if (!_.isEmpty(app.messages)) {
      dispatch({ type: 'clear.ex', error: [] })
    }
  }, [app.messages])

  return (
    <Context.Provider value={_.omit(demystify(data), ['Header', 'Footer'])}>
      <Suspense fallback={<Fragment></Fragment>}>
        {!_.includes(['/car-sales', '/checkin', '/bookings/confirmation'], location.pathname) &&
          quotation.mode != 'checkin' && <Itinerary />}
        {_.includes(['/bookings/create'], location.pathname) && quotation.mode != 'checkin' && (
          <SearchResult />
        )}

        <Switch>
          <Route exact path={`/checkin`} component={Checkin} />
          <Route exact path={`/bookings/create`} component={Create} />
          <Route exact path={`/bookings/confirmation`} component={Confirmation} />
          <Route exact path={`/locations`} component={LocationIndex} />
        </Switch>
      </Suspense>
    </Context.Provider>
  )
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        true || auth.token != '' /* bypass Auth for now */ ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: `/login`, state: { from: props.location } }} />
        )
      }
    />
  )
}

export default App
