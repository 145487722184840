import initialState from './initialState'
import _ from 'lodash'

function getData(obj, path, defaultValue = '') {
    const data = _.get(obj, path)
    return _.isEmpty(data) ? defaultValue : data
}

export default function ratesReducer(state = initialState.quotation, action) {
    switch (action.type) {
        case 'api.ex':
            if (action.response.reqdata.response == 'ResRates') {
                return {
                    ...state,
                    trips: {
                        ...state.trips,
                        [action.response.reqdata.trip]: {
                            ...state.trips[action.response.reqdata.trip],
                            rates: {
                                ...state.trips[action.response.reqdata.trip].rates,
                                [action.response.reqdata.index]: [],
                            },
                        },
                    },
                }
            }
            break
        case 'setQuotation':
            return {
                ...state,
                ...action.data,
            }
            break
        case 'getRates.ok':
        case 'getPromoRates.ok':
            if (_.has(action.response.result.api.ResRates, 'Messages.Message')) {
                const msg = _.isArray(action.response.result.api.ResRates.Messages.Message)
                    ? action.response.result.api.ResRates.Messages.Message[0]
                    : action.response.result.api.ResRates.Messages.Message
                if (msg.Text?.indexOf('LT:') != -1) {
                    return {
                        ...state,
                        trips: {
                            ...state.trips,
                            [action.response.reqdata.trip]: {
                                ...state.trips[action.response.reqdata.trip],
                                rates: {
                                    ...state.trips[action.response.reqdata.trip].rates,
                                    [action.response.reqdata.index]: [],
                                },
                            },
                        },
                    }
                }
            }
            let rates = action.response.result.api.ResRates.Rate
            if (!_.isArray(rates)) {
                rates = [{ ...rates }]
            }

            return {
                ...state,
                trips: {
                    ...state.trips,
                    [action.response.reqdata.trip]: {
                        ...state.trips[action.response.reqdata.trip],
                        rates: {
                            ...state.trips[action.response.reqdata.trip].rates,
                            [action.response.reqdata.index]: rates,
                        },
                    },
                },
            }
            break
        case 'getOptions.ok':
            const cover = _.find(action.response.result.api.ResOptions.Option, o => {
                return (
                    _.has(o, '@attributes.liability') &&
                    _.find(state.trips[action.response.reqdata.trip].extras, {
                        code: o['@attributes'].code,
                    })
                )
            })
            const { trip } = action.response.reqdata
            return {
                ...state,
                trips: {
                    ...state.trips,
                    [action.response.reqdata.trip]: {
                        ...state.trips[action.response.reqdata.trip],
                        cover: _.get(cover, '@attributes.code', state.trips[trip].cover),
                        options: action.response.result.api.ResOptions.Option,
                    },
                },
            }
        case 'getEstimate.ok':
            return {
                ...state,
                trips: {
                    ...state.trips,
                    [action.response.reqdata.trip]: {
                        ...state.trips[action.response.reqdata.trip],
                        estimate: action.response.result.api.ResEstimate.RenterEstimate,
                    },
                },
            }
        case 'newReservation.ok':
            if (_.get(action, 'response.result.api.skiprequest', false)) {
                return {
                    ...state,
                    trips: {
                        ...state.trips,
                        [action.response.reqdata.trip]: {
                            ...state.trips[action.response.reqdata.trip],
                            recorded: true,
                            paid: action.response.result.payment.isSuccessful,
                            payment: action.response.result.payment,
                        },
                    },
                }
            } else {
                return {
                    ...state,
                    trips: {
                        ...state.trips,
                        [action.response.reqdata.trip]: {
                            ...state.trips[action.response.reqdata.trip],
                            recorded: true,
                            res:
                                action.response.result.api.NewReservationResponse['@attributes']
                                    .reservationNumber,
                            conf: action.response.reqdata.conf,
                            paid: action.response.result.payment.isSuccessful,
                            payment: action.response.result.payment,
                        },
                    },
                }
            }
        case 'updateReservation.ok':
            return {
                ...state,
                trips: {
                    ...state.trips,
                    [state.trip]: {
                        ...state.trips[state.trip],
                        recorded: true,
                        paid: action.response.result.payment.isSuccessful,
                        payment: action.response.result.payment,
                    },
                },
            }
        case 'retrieveReservation.ok':
            const res = action.response.result.api.RetrieveReservationResponse.Res
            const currentTrip = action.response.reqdata.trip
            const options = getData(res, 'Option', [])
            const extras = _.isArray(options)
                ? _.map(options, o => {
                      return { code: o.Code, qty: o.Qty }
                  }) // - as an array
                : [
                      _.transform(
                          options,
                          (res, value, key) => {
                              res[key.toLowerCase()] = value
                          },
                          {},
                      ),
                  ] // - if there is only 1 selected extra, transform and return as array
            return {
                ...state,
                mode: action.response.reqdata.index,
                phase: 'create',
                trip: currentTrip,
                trips: {
                    ...state.trips,
                    [currentTrip]: {
                        ...state.trips[currentTrip],
                        res: getData(res, '@attributes.reservationNumber'),
                        conf: getData(res, 'Source.@attributes.confirmationNumber'),
                        cdp: getData(res, 'QuotedRate.@attributes.corporateRateID'),
                        pickup: getData(res, 'Pickup.@attributes.locationCode'),
                        return: getData(res, 'Return.@attributes.locationCode'),
                        from: getData(res, 'Pickup.@attributes.dateTime'),
                        until: getData(res, 'Return.@attributes.dateTime'),
                        car: getData(res, 'Vehicle.@attributes.classCode'),
                        notes: getData(res, 'ReservationMainNote'),
                        status: getData(res, '@attributes.status'),
                        paid: false,
                        extras: extras,
                    },
                },
                customer: {
                    ...state.customer,
                    custno: getData(res, 'Renter.@attributes.customerNumber'),
                    firstname: getData(res, 'Renter.RenterName.@attributes.firstName'),
                    lastname: getData(res, 'Renter.RenterName.@attributes.lastName'),
                    street1: getData(res, 'Renter.Address.Street.0'),
                    street2: getData(res, 'Renter.Address.Street.1'),
                    city: getData(res, 'Renter.Address.City'),
                    postalcode: getData(res, 'Renter.Address.PostalCode'),
                    countrycode: getData(res, 'Renter.Address.CountryCode'),
                    statecode: getData(res, 'Renter.Address.StateOrProvCode'),
                    email: getData(res, 'Renter.Address.Email'),
                    phone: getData(res, 'Renter.Address.CellTelephoneNumber'),
                    birthplace: getData(res, 'Renter.PlaceOfBirth'),
                    birthcountry: getData(res, 'Renter.CountryOfBirth'),
                    licno: getData(res, 'Renter.DrivingLicence.@attributes.Number'),
                    licissuer: getData(res, 'Renter.DrivingLicence.@attributes.Issuer'),
                    licexp: getData(res, 'Renter.DrivingLicence.@attributes.ExpiryDate'),
                    birthdate:
                        getData(res, 'Renter.AdditionalIdentification.Description') == 'DOB'
                            ? getData(res, 'Renter.AdditionalIdentification.Number')
                            : '',
                },
            }
        default:
            return state
    }
}
